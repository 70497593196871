.header__hamburger {
  display: none;
}

.header__hamburger:hover {
  opacity: 0.6;
}

@media screen and (max-width: 879px) {
  .header__hamburger {
    display: inline;
  }
}