.popup__title {
    margin: 24px 0 54px;
    /* width: 358px; */
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
}

@media screen and (max-width: 879px) {
    .popup__title {
        margin: 25px 0 76px;
        width: 238px;
        font-size: 18px;
        line-height: 122%;
    }
}