.popup__title_type_picture {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 125%;
  font-weight: 400;
  color: #FFFFFF;
  position: absolute;
  bottom: -20px;
  left: 0;
}