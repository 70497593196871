.popup_type_picture {
  width: 100%;
  height: 100%;
  background-color: #00000080;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.7s linear, visibility 0s linear 0.8s;
}