.picture-grid {
    margin: 50px 0 68px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px 17px;
}

@media screen and (max-width: 879px) {
    .picture-grid {
        margin: 36px 0 50px;
        grid-template-columns: 282px;
        grid-template-rows: repeat(6, 1fr);
        gap: 20px;
    }
}