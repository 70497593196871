.auth__link {
  margin: 0;
  margin-top: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  text-decoration: none;
}

.auth__link:hover {
  opacity: 0.6;
}