.auth {
  margin: 0;
  width: 358px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 879px) {
  .auth {
    width: 260px;
  }
}