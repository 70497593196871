.auth__submit {
  margin-top: 186px;
  width: 100%;
  height: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #000000;
  background-color: #FFFFFF;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.auth__submit:hover {
  opacity: 0.85;
}