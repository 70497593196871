.profile__title {
    margin: 0;
    padding: 0;
    grid-area: title;
    max-width: 373px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
    color: #FFFFFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media screen and (max-width: 879px) {
    .profile__title {
        max-width: 196px;
        font-size: 22px;
        line-height: 123%;
        justify-self: flex-start;
    }
}