.page__wrapper {
  padding: 0;
  margin: 0 auto;
  width: 880px;
  min-height: 100vh;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 879px) {
  .page__wrapper {
    width: 320px;
  }
}