.picture-grid__title {
    margin: 0;
    padding: 0;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
    grid-area: title;
    justify-self: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}