.form__error {
  margin-bottom: 13px;
  width: 358px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  color: #FF0000;

  visibility: hidden;
  display: none;
}

@media screen and (max-width: 879px) {
  .form__error {
      width: 238px;
  }
}