.menu__close {
  width: 40px;
  min-height: 40px;
  font-size: 40px;
  color: #ffffff;
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: none;
}

.menu__close:hover {
  opacity: 0.6;
}

@media screen and (max-width: 879px) {
  .menu__close {
    display: inline;
  }
}