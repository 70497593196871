.popup {
    width: 100%;
    height: 100%;
    background-color: #000000b3;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.7s linear, visibility 0s linear 0.8s;
}