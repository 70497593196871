.profile__subtitle {
    margin: 0;
    padding: 0;
    grid-area: subtitle;
    max-width: 415px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 122%;
    color: #FFFFFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: flex-end;
}

@media screen and (max-width: 879px) {
    .profile__subtitle {
        max-width: 282px;
        font-size: 14px;
        line-height: 121%;
    }
}