.menu__link{
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #A9A9A9;
  text-decoration: none;
}

@media screen and (max-width: 879px) {
  .menu__link {
    margin: 0 auto 40px;
  }
}