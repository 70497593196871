.popup__wrapper_type_picture {
  width: auto;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 879px) {
  .popup__wrapper_type_picture {
    width: unset;
    min-width: 240px;
    height: 290px;
  }
}