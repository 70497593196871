.auth__title {
  margin: 0;
  margin-bottom: 50px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #FFFFFF;
}

@media screen and (max-width: 879px) {
  .auth__title {
    font-size: 20px;
  }
}