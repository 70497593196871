.auth__input {
  margin-bottom: 30px;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #CCCCCC;
  background-color: #000000;
  border: none;
  border-bottom: 2px solid #CCCCCC;
}