.header__link {
  margin-top: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;
  text-decoration: none;
}

.header__link:hover {
  opacity: 0.6;
}

@media screen and (max-width: 879px) {
  .header__link {
    display: none;
  }
  .header__link_type_no-log {
    display: inline;
  }
}