.picture-grid__like {
    border: 0;
    grid-area: like;
    width: 21px;
    height: 19px;
    background-image: url(../../../images/like-button-inactive.png);
    background-color: inherit;
    background-size: 100%;
    cursor: pointer;
}

.picture-grid__like:hover {
    opacity: 0.5;
}

.picture-grid__like_active {
    background-image: url(../../../images/like-button-active.png);
}
