.menu__title {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;
}

@media screen and (max-width: 879px) {
  .menu__title {
    margin: 40px auto 18px;
  }
}