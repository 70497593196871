.popup__close {
    width: 40px;
    min-height: 40px;
    position: absolute;
    top: -40px;
    right: -40px;
    font-size: 40px;
    color: #ffffff;
    background-color: transparent;
    cursor: pointer;
    border: none;
}

.popup__close:hover {
    opacity: 0.6;
}

@media screen and (max-width: 879px) {
    .popup__close {
        right: 0;
        top: -50px;
        font-size: 30px;
    }
}