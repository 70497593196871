.header__logo {
  max-width: 173px;
  margin: 0 0 41px;
}

@media screen and (max-width: 879px) {
  .header__logo {
    max-width: 126px;
    margin: 0 0 27px;
  }
}