.popup__wrapper {
    width: 430px;
    height: 330px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

@media screen and (max-width: 879px) {
    .popup__wrapper {
        width: 282px;
        height: 322px;
    }
}