.header {
  width: 100%;
  margin: 45px 0 40px;
  border-bottom: 1px solid #545454;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

@media screen and (max-width: 879px) {
  .header {
    margin: 28px 0 42px;
    align-items: baseline;
  }
}